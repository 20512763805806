(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.jstoxml = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toXML = _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var DATA_TYPES = {
    ARRAY: 'array',
    BOOLEAN: 'boolean',
    DATE: 'date',
    FUNCTION: 'function',
    JSTOXML_OBJECT: 'jstoxml-object',
    NULL: 'null',
    NUMBER: 'number',
    OBJECT: 'object',
    STRING: 'string'
  };
  var PRIMITIVE_TYPES = [DATA_TYPES.STRING, DATA_TYPES.NUMBER, DATA_TYPES.BOOLEAN];
  var DEFAULT_XML_HEADER = '<?xml version="1.0" encoding="UTF-8"?>';
  var PRIVATE_VARS = ['_selfCloseTag', '_attrs'];

  /**
   * Determines the indent string based on current tree depth.
   */
  var getIndentStr = function getIndentStr() {
    var indent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return indent.repeat(depth);
  };

  /**
   * Sugar function supplementing JS's quirky typeof operator, plus some extra help to detect
   * "special objects" expected by jstoxml.
   * @example
   * getType(new Date());
   * // -> 'date'
   */
  var getType = function getType(val) {
    return Array.isArray(val) && DATA_TYPES.ARRAY || _typeof(val) === DATA_TYPES.OBJECT && val !== null && val._name && DATA_TYPES.JSTOXML_OBJECT || val instanceof Date && DATA_TYPES.DATE || val === null && DATA_TYPES.NULL || _typeof(val);
  };

  /**
   * Determines if a string is CDATA and shouldn't be touched.
   * @example
   * isCDATA('<![CDATA[<b>test</b>]]>');
   * // -> true
   */
  var isCDATA = function isCDATA(str) {
    return str.startsWith('<![CDATA[');
  };

  /**
   * Replaces matching values in a string with a new value.
   * @example
   * mapStr('foo&bar', { '&': '&amp;' });
   * // -> 'foo&amp;bar'
   */
  var mapStr = function mapStr() {
    var input = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var replacements = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var contentMap = arguments.length > 2 ? arguments[2] : undefined;
    var output = input;
    if (_typeof(input) === DATA_TYPES.STRING) {
      if (isCDATA(input)) {
        return input;
      }
      var regexp = new RegExp("(".concat(Object.keys(replacements).join('|'), ")(?!(\\w|#)*;)"), 'g');
      output = String(input).replace(regexp, function (str, entity) {
        return replacements[entity] || '';
      });
    }
    return typeof contentMap === 'function' ? contentMap(output) : output;
  };

  /**
   * Maps an object or array of arribute keyval pairs to a string.
   * @example
   * getAttributeKeyVals({ foo: 'bar', baz: 'g' });
   * // -> 'foo="bar" baz="g"'
   * getAttributeKeyVals([ { ⚡: true }, { foo: 'bar' } ]);
   * // -> '⚡ foo="bar"'
   */
  var getAttributeKeyVals = function getAttributeKeyVals() {
    var attributes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var replacements = arguments.length > 1 ? arguments[1] : undefined;
    var filter = arguments.length > 2 ? arguments[2] : undefined;
    var outputExplicitTrue = arguments.length > 3 ? arguments[3] : undefined;
    // Normalizes between attributes as object and as array.
    var attributesArr = Array.isArray(attributes) ? attributes : Object.entries(attributes).map(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        val = _ref2[1];
      return _defineProperty({}, key, val);
    });
    return attributesArr.reduce(function (allAttributes, attr) {
      var key = Object.keys(attr)[0];
      var val = attr[key];
      if (_typeof(filter) === DATA_TYPES.FUNCTION) {
        var shouldFilterOut = filter(key, val);
        if (shouldFilterOut) {
          return allAttributes;
        }
      }
      var replacedVal = replacements ? mapStr(val, replacements) : val;
      var valStr = !outputExplicitTrue && replacedVal === true ? '' : "=\"".concat(replacedVal, "\"");
      allAttributes.push("".concat(key).concat(valStr));
      return allAttributes;
    }, []);
  };

  /**
   * Converts an attributes object/array to a string of keyval pairs.
   * @example
   * formatAttributes({ a: 1, b: 2 })
   * // -> 'a="1" b="2"'
   */
  var formatAttributes = function formatAttributes() {
    var attributes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var replacements = arguments.length > 1 ? arguments[1] : undefined;
    var filter = arguments.length > 2 ? arguments[2] : undefined;
    var outputExplicitTrue = arguments.length > 3 ? arguments[3] : undefined;
    var keyVals = getAttributeKeyVals(attributes, replacements, filter, outputExplicitTrue);
    if (keyVals.length === 0) return '';
    var keysValsJoined = keyVals.join(' ');
    return " ".concat(keysValsJoined);
  };

  /**
   * Converts an object into an array of jstoxml-object.
   * @example
   * objToArray({ foo: 'bar', baz: 2 });
   * ->
   * [
   *   {
   *     _name: 'foo',
   *     _content: 'bar'
   *   },
   *   {
   *     _name: 'baz',
   *     _content: 2
   *   }
   * ]
   */
  var objToArray = function objToArray() {
    var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return Object.keys(obj).map(function (key) {
      return {
        _name: key,
        _content: obj[key]
      };
    });
  };

  /**
   * Determines if a value is a primitive JavaScript value (not including Symbol).
   * @example
   * isPrimitive(4);
   * // -> true
   */
  var isPrimitive = function isPrimitive(val) {
    return PRIMITIVE_TYPES.includes(getType(val));
  };

  /**
   * Determines if an XML string is simple (doesn't contain nested XML data).
   * @example
   * isSimpleXML('<foo />');
   * // -> false
   */
  var isSimpleXML = function isSimpleXML(xmlStr) {
    return !xmlStr.match('<');
  };

  /**
   * Assembles an XML header as defined by the config.
   */
  var getHeaderString = function getHeaderString(_ref4) {
    var header = _ref4.header,
      isOutputStart = _ref4.isOutputStart;
    var shouldOutputHeader = header && isOutputStart;
    if (!shouldOutputHeader) return '';
    var shouldUseDefaultHeader = _typeof(header) === DATA_TYPES.BOOLEAN;
    return shouldUseDefaultHeader ? DEFAULT_XML_HEADER : header;
  };

  /**
   * Recursively traverses an object tree and converts the output to an XML string.
   * @example
   * toXML({ foo: 'bar' });
   * // -> <foo>bar</foo>
   */
  var defaultEntityReplacements = {
    '<': '&lt;',
    '>': '&gt;',
    '&': '&amp;',
    '"': '&quot;'
  };
  var toXML = _exports.toXML = function toXML() {
    var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var _config$depth = config.depth,
      depth = _config$depth === void 0 ? 0 : _config$depth,
      indent = config.indent,
      _isFirstItem = config._isFirstItem,
      _config$_isOutputStar = config._isOutputStart,
      _isOutputStart = _config$_isOutputStar === void 0 ? true : _config$_isOutputStar,
      header = config.header,
      _config$attributeRepl = config.attributeReplacements,
      rawAttributeReplacements = _config$attributeRepl === void 0 ? {} : _config$attributeRepl,
      attributeFilter = config.attributeFilter,
      _config$attributeExpl = config.attributeExplicitTrue,
      attributeExplicitTrue = _config$attributeExpl === void 0 ? false : _config$attributeExpl,
      _config$contentReplac = config.contentReplacements,
      rawContentReplacements = _config$contentReplac === void 0 ? {} : _config$contentReplac,
      contentMap = config.contentMap,
      _config$selfCloseTags = config.selfCloseTags,
      selfCloseTags = _config$selfCloseTags === void 0 ? true : _config$selfCloseTags;
    var shouldTurnOffAttributeReplacements = typeof rawAttributeReplacements === 'boolean' && !rawAttributeReplacements;
    var attributeReplacements = shouldTurnOffAttributeReplacements ? {} : _objectSpread(_objectSpread({}, defaultEntityReplacements), rawAttributeReplacements);
    var shouldTurnOffContentReplacements = typeof rawContentReplacements === 'boolean' && !rawContentReplacements;
    var contentReplacements = shouldTurnOffContentReplacements ? {} : _objectSpread(_objectSpread({}, defaultEntityReplacements), rawContentReplacements);
    var shouldAddNewlines = typeof indent === 'string';

    // Determines indent based on depth.
    var indentStr = getIndentStr(indent, depth);

    // For branching based on value type.
    var valType = getType(obj);
    var headerStr = getHeaderString({
      header: header,
      indent: indent,
      depth: depth,
      isOutputStart: _isOutputStart
    });
    var isOutputStart = _isOutputStart && !headerStr && _isFirstItem && depth === 0;
    var preIndentStr = shouldAddNewlines && !isOutputStart ? '\n' : '';
    var outputStr = '';
    switch (valType) {
      case DATA_TYPES.JSTOXML_OBJECT:
        {
          // Processes a specially-formatted object used by jstoxml.

          var _name = obj._name,
            _content = obj._content;

          // Output text content without a tag wrapper.
          if (_content === null && typeof contentMap !== 'function') {
            outputStr = "".concat(preIndentStr).concat(indentStr).concat(_name);
            break;
          }

          // Handles arrays of primitive values. (#33)
          var isArrayOfPrimitives = Array.isArray(_content) && _content.every(isPrimitive);
          if (isArrayOfPrimitives) {
            var primitives = _content.map(function (a) {
              return toXML({
                _name: _name,
                _content: a
              }, _objectSpread(_objectSpread({}, config), {}, {
                depth: depth,
                _isOutputStart: false
              }));
            });
            return primitives.join('');
          }

          // Don't output private vars (such as _attrs).
          if (PRIVATE_VARS.includes(_name)) break;

          // Process the nested new value and create new config.
          var newVal = toXML(_content, _objectSpread(_objectSpread({}, config), {}, {
            depth: depth + 1,
            _isOutputStart: isOutputStart
          }));
          var newValType = getType(newVal);
          var isNewValSimple = isSimpleXML(newVal);
          var isNewValCDATA = isCDATA(newVal);

          // Pre-tag output (indent and line breaks).
          var preTag = "".concat(preIndentStr).concat(indentStr);

          // Special handling for comments, preserving preceding line breaks/indents.
          if (_name === '_comment') {
            outputStr += "".concat(preTag, "<!-- ").concat(_content, " -->");
            break;
          }

          // Tag output.
          var valIsEmpty = newValType === 'undefined' || newVal === '';
          var globalSelfClose = selfCloseTags;
          var localSelfClose = obj._selfCloseTag;
          var shouldSelfClose = _typeof(localSelfClose) === DATA_TYPES.BOOLEAN ? valIsEmpty && localSelfClose : valIsEmpty && globalSelfClose;
          var selfCloseStr = shouldSelfClose ? '/' : '';
          var attributesString = formatAttributes(obj._attrs, attributeReplacements, attributeFilter, attributeExplicitTrue);
          var tag = "<".concat(_name).concat(attributesString).concat(selfCloseStr, ">");

          // Post-tag output (closing tag, indent, line breaks).
          var preTagCloseStr = shouldAddNewlines && !isNewValSimple && !isNewValCDATA ? "\n".concat(indentStr) : '';
          var postTag = !shouldSelfClose ? "".concat(newVal).concat(preTagCloseStr, "</").concat(_name, ">") : '';
          outputStr += "".concat(preTag).concat(tag).concat(postTag);
          break;
        }
      case DATA_TYPES.OBJECT:
        {
          // Iterates over keyval pairs in an object, converting each item to a special-object.

          var keys = Object.keys(obj);
          var outputArr = keys.map(function (key, index) {
            var newConfig = _objectSpread(_objectSpread({}, config), {}, {
              _isFirstItem: index === 0,
              _isLastItem: index + 1 === keys.length,
              _isOutputStart: isOutputStart
            });
            var outputObj = {
              _name: key
            };
            if (getType(obj[key]) === DATA_TYPES.OBJECT) {
              // Sub-object contains an object.

              // Move private vars up as needed.  Needed to support certain types of objects
              // E.g. { foo: { _attrs: { a: 1 } } } -> <foo a="1"/>
              PRIVATE_VARS.forEach(function (privateVar) {
                var val = obj[key][privateVar];
                if (typeof val !== 'undefined') {
                  outputObj[privateVar] = val;
                  delete obj[key][privateVar];
                }
              });
              var hasContent = typeof obj[key]._content !== 'undefined';
              if (hasContent) {
                // _content has sibling keys, so pass as an array (edge case).
                // E.g. { foo: 'bar', _content: { baz: 2 } } -> <foo>bar</foo><baz>2</baz>
                if (Object.keys(obj[key]).length > 1) {
                  var newContentObj = Object.assign({}, obj[key]);
                  delete newContentObj._content;
                  outputObj._content = [].concat(_toConsumableArray(objToArray(newContentObj)), [obj[key]._content]);
                }
              }
            }

            // Fallthrough: just pass the key as the content for the new special-object.
            if (typeof outputObj._content === 'undefined') outputObj._content = obj[key];
            var xml = toXML(outputObj, newConfig);
            return xml;
          }, config);
          outputStr = outputArr.join('');
          break;
        }
      case DATA_TYPES.FUNCTION:
        {
          // Executes a user-defined function and returns output.

          var fnResult = obj(config);
          outputStr = toXML(fnResult, config);
          break;
        }
      case DATA_TYPES.ARRAY:
        {
          // Iterates and converts each value in an array.
          var _outputArr = obj.map(function (singleVal, index) {
            var newConfig = _objectSpread(_objectSpread({}, config), {}, {
              _isFirstItem: index === 0,
              _isLastItem: index + 1 === obj.length,
              _isOutputStart: isOutputStart
            });
            return toXML(singleVal, newConfig);
          });
          outputStr = _outputArr.join('');
          break;
        }

      // fallthrough types (number, string, boolean, date, null, etc)
      default:
        {
          outputStr = mapStr(obj, contentReplacements, contentMap);
          break;
        }
    }
    return "".concat(headerStr).concat(outputStr);
  };
  var _default = _exports.default = {
    toXML: toXML
  };
});
